<template>
  <!-- eslint-disable vue/no-v-html -->
  <article data-test="message" class="message" :class="type">
    <section class="message__content">
      <div class="message__content-left">
        <ev-icon class="message__icon--left" :color="type" :name="iconName" />
        <div class="message__content-info">
          <p class="message__description">
            <span v-html="text" data-test="message-description" />
          </p>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'

export default {
  name: 'FailWarning',
  components: {
    EvIcon
  },
  props: {
    type: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
  .warning {
    background-color: rgba(255, 180, 0, 0.1);
  }

  .success {
    background-color: rgba(38, 155, 35, 0.1);
  }

  .message {
    align-items: center;
    border-radius: var(--b-radius);
    display: flex;
    justify-content: space-between;
    margin-bottom: $base * 6;
    padding: $base * 4;

    p {
      font: var(--tx-caption);
      margin-bottom: 0;
      margin-top: $base;

      strong {
        font: var(--tx-caption);
        font-weight: 700;
      }
    }

    &__content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-left {
        align-items: center;
        display: flex;
      }
    }

    &__icon--left {
      margin-right: $base * 4;
    }
  }
</style>
