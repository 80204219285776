<template>
  <!-- eslint-disable vue/no-v-html -->
  <signup-fail-template data-test="layout">
    <template #page_title>
      <div data-test="title" v-html="$t(`${langPath}.title`)" />
    </template>
    <template #content>
      <message
        data-test="warning_message"
        type="warning"
        :text="warningText"
        icon-name="exclamation-triangle"
      />

      <p data-test="description_line1"><strong>{{ $t(`${langPath}.line1`) }}</strong></p>
      <p data-test="description_line2">{{ $t(`${langPath}.line2`) }}</p>
      <ev-button
        full-width
        color="red"
        variant="primary"
        @click="requestAccess"
        data-test="button"
      >
        {{ $t(`${langPath}.accessButton`) }}
      </ev-button>
    </template>
  </signup-fail-template>
</template>

<script>
import SignupFailTemplate from './components/SignupFailTemplate'
import EvButton from '@revelotech/everestV2/EvButton'
import Message from './Message'
import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER } from '@/store/namespaces'
import signup from '@/repository/companies-app/signup'
import eventBus from '@/support/event-bus'

const authHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'ActiveCompany',
  components: {
    SignupFailTemplate,
    EvButton,
    Message
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...authHelper.mapState(['currentUser']),
    warningText () {
      return this.$t(
        `${this.langPath}.warning`, {
          emailDomain:
            this.currentUser?.email?.split('@')[1]
        }
      )
    }
  },
  mounted () {
    this.getCurrentUser()
  },
  methods: {
    ...authHelper.mapActions(['getCurrentUser']),
    requestAccess () {
      signup.failRequestAccess()
        .then(() => {
          this.$router.push({ name: 'AccessRequestSent' })
        })
        .catch(() =>
          eventBus.emit('alert-notification', {
            message: this.$t(`${this.langPath}.notificationFail`),
            variant: 'error'
          })
        )
    }
  }
}
</script>

<style lang="scss">
.signup-fail-template {
  &__main {
    &-content {
      &-box {
        button {
          width: 100%;
        }
      }
    }
  }
}

.notification-container__item {
  position: absolute;
  right: $base*2;
  top: $base*5;
  transition: auto 2s;

  button {
    width: fit-content;
  }
}
</style>
