<template>
  <div class="signup-fail-template">
    <section class="signup-fail-template__main">
      <section class="signup-fail-template__main-logo">
        <img
          data-test="revelo-logo-mini"
          src="@/assets/images/logo-revelo-small.svg"
        >
      </section>
      <section class="signup-fail-template__main-content">
        <section class="signup-fail-template__main-content-title">
          <slot name="page_title" />
        </section>

        <section class="signup-fail-template__main-content-box">
          <slot name="content" />
        </section>

        <section class="signup-fail-template__main-content-help" v-if="!hideHelpLink">
          <ev-button
            data-test="help-button"
            color="blue"
            variant="tertiary"
            @click="askForHelpClick()"
          >
            <ev-icon color="blue" name="question-circle" /> {{ $t(`${langPath}.helpButton`) }}
          </ev-button>
        </section>

        <section class="signup-fail-template__main-content-back">
          <ev-button data-test="back-button" color="blue" variant="tertiary" @click="signOut">
            <ev-icon color="blue" name="arrow-left" /> {{ $t(`${langPath}.backButton`) }}
          </ev-button>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import { companiesUrl, csWhatsappUrl } from '@/helpers/constants/external-urls'

export default {
  name: 'SignupFailTemplate',
  components: { EvButton, EvIcon },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    hideHelpLink () {
      return this.$route.meta?.hideHelpLink
    }
  },
  methods: {
    askForHelpClick () {
      window.open(csWhatsappUrl, '_blank')
    },
    signOut () {
      window.location.assign(`${companiesUrl}/users/sign_out`)
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: var(--bg-white) !important;
}

.signup-fail-template {
  align-content: stretch;
  background-color: var(--bg-white);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow-y: auto;

  &__main {
    margin-top: $base * 10;
    width: $base * 84;

    &-content {
      margin-top: $base * 5;

      button {
        &:focus {
          outline: none;
        }
      }

      &-box {
        font: var(--tx-body);
        margin-bottom: 26px;

        strong {
          font: var(--tx-body-he);
        }

        p {
          margin-bottom: $base * 8;
        }
      }

      &-title {
        color: var(--b-blue);
        font: var(--tx-title-1);
        margin-bottom: $base * 6;

        strong {
          color: var(--b-red);
          font: var(--tx-title-1);
          font-weight: 600;
        }
      }

      &-help {
        .ev-icon {
          margin-right: $base * 2;
        }
      }

      &-back {
        .ev-icon {
          margin-right: $base * 2;
        }
      }
    }
  }

  &__logo-mini {
    @include margin(bottom, 4);
  }

  @include breakpoint(md) {
    display: grid;

    &__logo {
      @include margin(bottom, 24);
    }
  }
}
</style>
